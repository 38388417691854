<template>
    <button class="w-full mt-1 bg-transparent p-3 border-2 border-opacity-100 border-white rounded-md hover:bg-gray-600 transition-all focus:outline-none outline-none">
        <img class="h-6 inline mr-3" :src="img" />
        Log in with {{ service }}
    </button>
</template>

<script>

export default {
    props: {
        service: {
            required: true,
            type: String,
        },
        img: {
            required: true,
            type: String,
        }
    }
}

</script>
