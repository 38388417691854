<template>
  <div class="font-body dark:bg-gray-700 flex h-screen m-auto">
    <div class="mx-auto self-center dark:bg-gray-800 p-5 rounded-xl shadow-2xl text-white">
      <form class="text-center" @submit.prevent="login">
        <p class="font-bold text-6xl">Login</p>
        <br />
        <input class="mt-0 mb-2 bg-transparent block w-full px-0.5 border-0 border-b-2 border-gray-900 focus:ring-0 focus:border-gray-300 text-white" type="email" placeholder="Email address" v-model="email" />
        <!--<input class="mb-2 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" v-model="email" type="email" name="email" id="email" placeholder="Email address" required />-->
        <button class="text-lg rounded-md bg-purple-500 px-5 py-2" type="submit">Login</button>
        <p class="text-white" v-if="isLoggedIn">SUCCESSFULLY LOGGED IN!</p>
      </form>
      <!-- sign in options -->
      <div class="">
        <p class="my-10 text-white text-2xl text-center">
          or
        </p>

        <div>
          <ExternalSignInButton service="Google" :img="exticons.google" v-on:click="clickedGoogle" />
          <br />
          <ExternalSignInButton service="Github" :img="exticons.github.light" v-on:click="clickedGithub" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalSignInButton from '@/components/ExternalSignInButton.vue'
import googleicon from "@/assets/external_icons/google.png"
import githublighticon from "@/assets/external_icons/github_light.png"
import githubdarkicon from "@/assets/external_icons/github_dark.png"

export default {
  data() {
    return {
      email: "",
      exticons: {
        google: googleicon,
        github: {
          light: githublighticon,
          dark: githubdarkicon
        }
      }
    }
  },
  name: "Home",
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          email: this.email,
        })
        .then(() => {
          console.log("login promise completed!")
          this.loggedIn = true
        })
        .catch((err) => {
          console.error(err)
        })
    },
    clickedGoogle() {
      this.$store.dispatch("loginWith", {
        service: "google"
      })
    },
    clickedGithub() {
      this.$store.dispatch("loginWith", {
        service: "github"
      })
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user !== null
    }
  },
  components: {
    ExternalSignInButton
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
*,
*::before,
*::after {
	box-sizing: border-box;
	font-family: 'Inter', sans-serif;
	outline: none;
}
body {
	margin: 0;
	color: #333;
	background-color: #fff;
	min-height: 100%;
}
form,
label {
	display: flex;
	flex-flow: column;
	text-align: center;
}
.email-input {
	padding: 10px;
	margin: 1rem auto;
	border: 1px solid #ccc;
	border-radius: 50px;
	outline: none;
	transition: 0.5s;
	width: 80%;
}
.email-input:focus {
	border: 1px solid #42b983;
}
.submit {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	justify-content: space-between;
}
.submit > a {
	text-decoration: none;
}
.submit > button {
	padding: 0.6rem 1rem;
	cursor: pointer;
	background: #fff;
	border: 1px solid #ccc;
	border-radius: 50px;
	width: 80%;
	outline: none;
	transition: 0.3s;
	margin: 0 auto;
	font-size: 14px;
}
.submit > button:hover {
	border-color: #42b983;
}
.error {
	color: brown;
	margin: 1rem 0 0;
}
.container {
	max-width: 42rem;
	margin: 0 auto;
	padding: 2rem 1.25rem;
	overflow: auto;
}
.login {
	max-width: 20rem;
	margin: 0px auto;
	padding: 1rem;
	border: 1px solid rgb(204, 204, 204);
	border-radius: 4px;
	text-align: center;
}

.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

</style>